import { QueryClient } from 'react-query';
import { STATUS_CODES } from 'utils/http';
import { HTTP_STATUS_CODES_TO_LOCK_UI } from 'services/http';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const isNotFound = STATUS_CODES.NOT_FOUND === error.status;
        const isUiLocked = HTTP_STATUS_CODES_TO_LOCK_UI.includes(error.status);
        if (isNotFound || isUiLocked) return false;
        if (failureCount < 2) return true;
        return false;
      },
    },
  },
});

export default queryClient;
