import { css } from 'styled-components';

const sanitize = css`
  /**
   * 1. Add border box sizing in all browsers (opinionated)
   *    https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   * 2. Change the line height in all browsers (opinionated)
   * 3. Prevent adjustments of font size after orientation changes in iOS.
   */

  html {
    box-sizing: border-box; /* 1 */
    line-height: 1.5; /* 2 */
    text-size-adjust: 100%; /* 3 */
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit; /* 1 */
  }

  /**
   * Remove the margin in all browsers (opinionated).
   */

  body {
    margin: 0;
  }

  /**
   * Correct the font size and margin on h1 elements within section and article contexts
   * in Chrome, Edge, Firefox, and Safari.
   */

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
`;

export default sanitize;
