import get from 'lodash/get';

/**
 * Devuelve una cadena omitiendo los caracteres especiales...
 * @see https://stackoverflow.com/questions/4374822/remove-all-special-characters-with-regexp
 * @param   {string} param
 * @returns {string}
 */
const getParamWithoutSpecialCharacters = param => param.replace(/[^\w]/g, '');

/**
 * Reemplaza un parámetro como valor, es decir, omitiendo las comillas que pueda tener alrededor por el valor
 * @param   {Object} arg
 * @param   {string} arg.source - Cadena con parámetros. Por ejemplo: '{"foo":"{{param1}}"}'
 * @param   {string} arg.param  - Parámetro. Por ejemplo: {{param1}}
 * @param   {*}      arg.value  - Valor. Por ejemplo: true o 'test' o [1, 2, 3] o { bar: 'baz' }
 * @returns {string}
 *
 * @example
 * replaceParamAsValue({ source: '{"foo":"{{param1}}"}', param: '{{param1}}', value: true });
 * // => '{"foo":true}'
 *
 * @example
 * replaceParamAsValue({ source: '{"foo":"{{param1}}"}', param: '{{param1}}', value: 'test' });
 * // => '{"foo":"test"}'
 *
 * @example
 * replaceParamAsValue({ source: '{"foo":"{{param1}}"}', param: '{{param1}}', value: [1, 2, 3] });
 * // => '{"foo":[1,2,3]}'
 */
const replaceParamAsValue = ({ source, param, value }) => {
  const paramRegex = new RegExp(`['"]${param}['"]`);
  const valueStringified = JSON.stringify(value);
  return source.replace(paramRegex, valueStringified);
};

/**
 * Reemplaza un parámetro como string.
 * Dependiendo del tipo del valor su resultado será el de la función `toString`
 * @param   {Object} arg
 * @param   {string} arg.source - Cadena con parámetros. Por ejemplo: '/foo/{{param1}}' o '{"foo":"/bar/{{param1}}"}'
 * @param   {string} arg.param  - Parámetro. Por ejemplo: {{param1}}
 * @param   {*}      arg.value  - Valor: Por ejemplo: true o 'test'
 * @returns {string}
 *
 * @example
 * replaceParamAsString({ source: '/foo/{{param1}}', param: '{{param1}}', value: true })
 * // => '/foo/true'
 *
 * @example
 * replaceParamAsString({ source: '{"foo":"/bar/{{param1}}"}', param: '{{param1}}', value: 'test })
 * // => '{"foo":"/bar/true"}'
 */
const replaceParamAsString = ({ source, param, value }) => source.replace(param, value);

/**
 * Rellena un objeto de parámetros obteniendo los valores de un scope
 * @param   {Object}          arg
 * @param   {Object.<string>} arg.params - Objeto de parámetros
 * @param   {Object}          arg.scope  - Objeto de valores para rellenar
 * @returns {Object}
 */
const fillParams = ({ params, scope }) => {
  if (!params) return null;

  const newParams = {};
  for (let [key, value] of Object.entries(params)) {
    newParams[key] = get(scope, value);
  }

  return newParams;
};

/**
 * Reemplaza los parámetros de la string proporcionada.
 * Por defecto los parámetros se deben envolver con dobles llaves, por ejemplo {{foo}}
 * @TODO: Limpiar los valores repetidos de paramNames para recorrer menos veces
 * @param   {string} source                           - Cadena a reemplazar. Ej.: 'foo{{param1}}'
 * @param   {Object} params                           - Parámetros a reemplazar
 * @param   {RegExp} [paramsRegExp=/\{\{(.*?)\}\}+/g] - Expresión regular para identificar los parámetros. Ej.: /:[^\s/]+/g
 * @returns {string}
 *
 * @example
 * replaceParamsFromString({ source: 'foo/{{param1}}', params: { param1: 'bar' } })
 * // => foo/bar
 *
 * @example
 * replaceParamsFromString({ source: 'foo/:param1', params: { param1: '1337' }, paramsRegExp: /:[^\s/]+/g })
 * // => foo/1337
 */
const replaceParamsFromString = ({ source, params, paramsRegExp = /\{\{(.*?)\}\}+/g }) => {
  if (!source || !params) return source;

  let result = source;
  const paramNames = result.match(paramsRegExp);

  if (!paramNames || (paramNames && paramNames.length === 0)) return source;

  paramNames.forEach(param => {
    const paramWithoutSpecialChars = getParamWithoutSpecialCharacters(param);
    const value = get(params, paramWithoutSpecialChars);
    result = replaceParamAsString({ source: result, param, value });
  });

  return result;
};

/**
 * Reemplaza los parámetros del objeto proporcionado.
 * Por defecto los parámetros se deben envolver con dobles llaves, por ejemplo {{foo}}
 * @TODO: Limpiar los valores repetidos de paramNames para recorrer menos veces
 * @param   {Object} source                           - Objeto a reemplazar. Ej.: { foo: "{{param1}}", bar: "{{param2}}" }
 * @param   {Object} params                           - Parámetros a reemplazar
 * @param   {RegExp} [paramsRegExp=/\{\{(.*?)\}\}+/g] - Expresión regular para identificar los parámetros
 * @returns {Object}
 */
const replaceParamsFromObject = ({ source, params, paramsRegExp = /\{\{(.*?)\}\}+/g }) => {
  if (!source || !params) return source;

  let result = JSON.stringify(source);
  const paramNames = result.match(paramsRegExp);

  if (!paramNames || (paramNames && paramNames.length === 0)) return source;

  paramNames.forEach(param => {
    const paramWithoutSpecialChars = getParamWithoutSpecialCharacters(param);
    const value = get(params, paramWithoutSpecialChars);
    result = replaceParamAsValue({ source: result, param, value });
    result = replaceParamAsString({ source: result, param, value });
  });

  return JSON.parse(result);
};

export { fillParams, replaceParamsFromString, replaceParamsFromObject };
