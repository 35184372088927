import { STATUS_CODES } from 'utils/http';
import { Observable } from 'utils/observables';

const HTTP_STATUS_CODES_TO_LOCK_UI = [
  STATUS_CODES.FORBIDDEN,
  STATUS_CODES.PAYMENT_REQUIRED,
  STATUS_CODES.NOT_FOUND,
];

/**
 * Observable para notificar a los suscriptores cuando ocurra un error HTTP
 */
const httpStatusErrorObservable = new Observable();

/**
 * Notifica un error de HTTP (httpStatusCode) a quienes estén suscritos al observable `httpStatusErrorObservable`.
 * @see https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
 * @param   {Object} arg
 * @param   {number} arg.status
 * @param   {Object} arg.data
 */
const notifyHttpStatusError = ({ status, data }) => {
  httpStatusErrorObservable.notify({ httpStatusCode: status, httpStatusData: data });
};

export { HTTP_STATUS_CODES_TO_LOCK_UI, httpStatusErrorObservable, notifyHttpStatusError };
