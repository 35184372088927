/**
 * Clase para notificar a los suscriptores cuando ocurre un evento
 * @see https://www.patterns.dev/posts/observer-pattern/
 */
class Observable {
  constructor() {
    this.observers = [];
  }

  subscribe(f) {
    this.observers.push(f);
  }

  unsubscribe(f) {
    this.observers = this.observers.filter(subscriber => subscriber !== f);
  }

  notify(data) {
    this.observers.forEach(observer => observer(data));
  }
}

export { Observable };
