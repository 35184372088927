/**
 * Comprueba si la variable que recibe es un objeto
 * @param   {*}       value
 * @returns {boolean}
 */
const isObject = value =>
  typeof value === 'object' && value?.constructor === Object && value !== null;

/**
 * Aplana un objeto profundo en un objeto de un nivel con las rutas de sus propiedades como clave
 * @see https://gist.github.com/penguinboy/762197
 * @param   {Object} object          - Objeto
 * @param   {string} [separator='.'] - Separador entre propiedades
 * @returns {Object}
 */
function flatten(object, separator = '.') {
  return Object.assign(
    {},
    ...(function _flatten(child, path = []) {
      return [].concat(
        ...Object.keys(child).map(key =>
          typeof child[key] === 'object' && child[key] !== null
            ? _flatten(child[key], path.concat([key]))
            : { [path.concat([key]).join(separator)]: child[key] },
        ),
      );
    })(object),
  );
}

// https://stackoverflow.com/questions/25421233/javascript-removing-undefined-fields-from-an-object/37836669
// https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
const removeEmptyProperties = obj => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key, value]) => {
        if (value === undefined) return false;
        if (value === null) return false;
        if (value === '') return false;
        if (typeof value === 'number' && isNaN(value)) return false;
        if (Array.isArray(value) && value.length === 0) return false;
        if (isObject(value) && Object.keys(value).length === 0) return false;
        return true;
      })
      .map(([key, value]) => {
        return typeof value === 'object' && !Array.isArray(value)
          ? [key, removeEmptyProperties(value)]
          : [key, value];
      }),
  );
};

export { isObject, flatten, removeEmptyProperties };
