import get from 'lodash/get';
import { isObject } from './objects';
import { isFile, isFileList, isFileArray } from './files';

const parseRequestData = ({ fields, values, extraData, format }) => {
  // NOTA: se envían todos los values (no solo los que están definidos en fields) además del extraData ya que se suele mandar el modelo
  if (format === 'simple') {
    // Añade los datos extra si los tiene
    const fullData = { ...extraData, ...values };

    return fullData;
  }

  if (format === 'detailed') {
    const mutableFields = fields?.filter(f => f.fieldType !== 'label');
    const valuesKeys = mutableFields?.map(f => f.name);

    const data = valuesKeys?.map(key => {
      const field = mutableFields?.find(f => f.name === key);
      const value = get(values, key, null);

      switch (typeof value) {
        case 'string':
        case 'number':
        case 'boolean':
          if (field.fieldType === 'filter' && field.values) {
            return {
              key: key,
              label: field.label,
              value: value,
              valueLabel: field.values.find(v => v.value === value).label,
            };
          }

          return {
            key: key,
            label: field.label,
            value: value,
          };
        default:
          return {
            key: key,
            label: field.label,
            value: value,
          };
      }
    });

    // Solo cuando se esté confirmando un 428
    const confirmed = values.confirmed;

    // Añade los datos extra si los tiene (al mismo nivel que data)
    const fullData = { ...extraData, data, confirmed };

    // Añade los IDs si los tiene
    // @QUESTION Recibir los IDs en extraData para omitir este paso ?
    const ids = Object.keys(values)
      .filter(key => ['_id', 'id'].indexOf(key) >= 0)
      .reduce((obj, key) => Object.assign(obj, { [key]: values[key] }), {});

    const hasIds = Object.keys(ids).length > 0;

    return hasIds ? { ...ids, ...fullData } : fullData;
  }

  if (format === 'formData') {
    const newData = new FormData();

    // Añade los datos extra si los tiene
    const fullData = { ...extraData, ...values };

    Object.keys(fullData).forEach(key => {
      const currentValue = fullData[key];

      if (currentValue === undefined) return;

      if (isFileList(currentValue) || isFileArray(currentValue)) {
        for (const file of currentValue) {
          newData.append(key, file);
        }
        return;
      }

      if (isFile(currentValue)) {
        return newData.append(key, currentValue);
      }

      if (Array.isArray(currentValue)) {
        currentValue.forEach((value, index) => {
          const rawValue = currentValue[index];
          const parsedValue =
            isObject(rawValue) || Array.isArray(rawValue) ? JSON.stringify(rawValue) : rawValue;
          newData.append(`${key}[]`, parsedValue);
        });
        return;
      }

      if (isObject(currentValue) && Object.keys(currentValue).length > 0) {
        return newData.append(key, JSON.stringify(currentValue));
      }

      return newData.append(key, currentValue);
    });

    return newData;
  }
};

export { parseRequestData };
